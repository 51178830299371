import React from 'react';

const IconSvg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512.024 512.024"
    xmlSpace="preserve"
    width="131px"
    height="131px"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <polygon
        style={{ fill: "#0D91BA" }}
        points="176.896,67.3 150.88,36.084 52.032,36.084 20.808,67.3 20.808,108.924 437.04,108.924 437.04,67.3"
      />
      <rect
        y="108.924"
        style={{ fill: "#25B6D2" }}
        width="457.84"
        height="299.2"
      />
      <rect
        x="46.824"
        y="88.092"
        style={{ fill: "#FFFFFF" }}
        width="364.2"
        height="20.808"
      />
      <circle
        style={{ fill: "#E04F5F" }}
        cx="426.32"
        cy="390.236"
        r="85.704"
      />
      <g>
        <rect
          x="381.672"
          y="386.252"
          style={{ fill: "#FFFFFF" }}
          width="89.248"
          height="8"
        />
        <rect
          x="422.296"
          y="345.612"
          style={{ fill: "#FFFFFF" }}
          width="8"
          height="89.248"
        />
      </g>
    </g>
  </svg>
);

export default IconSvg;
