import React from 'react';

function underline() {
  return (
    <svg
      width="500px"
      height="auto"
      viewBox="0 0 690 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M689.96 8.12046C689.781 7.3607 689.368 6.71847 688.812 6.33174C688.673 6.23606 688.528 6.15658 688.378 6.0943C688.258 6.03322 688.134 5.98552 688.008 5.95182L687.548 5.84103L686.872 5.71439C683.695 5.35032 680.798 5.27118 677.773 5.12871L668.841 4.84377L650.797 4.51137L605.471 4.09979L613.765 3.79903H615.284C624.676 3.48244 621.371 2.89677 624.216 2.43772C637.207 0.379906 626.947 -0.253273 588.103 0.0949722C579.643 0.0949722 570.685 0.0949722 562.059 0.0949722L488.212 0L414.736 0.348247L272.912 0.158291L202.013 0.0633188H166.487C154.684 0.0633188 142.854 0.0633164 131.025 0.22161C117.328 0.379903 103.632 0.606791 89.9353 0.902272L69.3777 1.44048L59.0798 1.85203L56.5276 1.9945L55.2515 2.08947L54.5369 2.16862H54.1286H53.8223L53.4905 2.27943C54.0775 3.19753 51.5764 0.0949654 52.4697 7.18651C53.3885 8.12044 53.5799 7.93049 53.6564 8.00964H53.9117H54.2051H54.6135H55.3153H56.5914H59.1436C72.9507 8.27874 86.5793 8.24709 100.297 8.29458H115.342C97.477 8.3579 79.6119 8.45286 61.7468 8.5795C43.2819 8.72196 28.0328 9.18102 17.301 9.84585C6.56916 10.5107 0.380178 11.3338 0.0101155 12.2677C-0.691728 14.1039 35.2937 13.4866 56.4511 13.4075C124.168 12.9642 191.958 12.8587 259.82 13.0909L463.456 13.4391C522.156 13.5499 613.663 13.4391 639.555 13.17L653.592 12.9959C659.411 12.9959 665.281 12.9959 671.189 12.7901C674.141 12.7162 677.118 12.6002 680.121 12.4419C681.614 12.4419 683.107 12.2836 684.626 12.1411C685.392 12.1411 686.132 12.0145 686.974 11.8878C687.153 11.8878 687.421 11.8087 687.676 11.7612H687.854H687.957L688.25 11.6662C688.434 11.5876 688.613 11.4924 688.786 11.3813L689.041 11.2072C689.271 10.9381 690.202 10.8431 689.96 8.12046ZM329.915 8.51619C300.259 8.57951 270.513 8.57951 240.678 8.51619H305.758H345.776H329.915Z"
        fill="#3C206F"
      />
    </svg>
  );
}

export default underline;
