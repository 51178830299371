import React from 'react'

function logo() {
  return (
    <svg width="200" height="50" viewBox="0 0 1572 975" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M730.629 0.117949L125.631 115.392C123.817 115.729 122.198 116.815 121.217 118.358C120.211 119.902 119.893 121.807 120.359 123.591L274.079 722.629C274.741 725.234 276.924 727.163 279.621 727.573C282.319 727.983 284.992 726.777 286.415 724.51L737.692 10.1984C738.354 9.13727 738.722 7.93157 738.722 6.67754C738.722 5.30294 738.305 4.00066 737.52 2.89133C736 0.720905 733.302 -0.388484 730.653 0.117949H730.629ZM220.566 110.882L355.157 590.354L283.3 704.108L135.245 127.136L220.566 110.882ZM481.631 61.1552L460.073 109.411C459.338 111.075 459.313 113.004 460.049 114.692C460.785 116.381 462.207 117.707 463.948 118.334L503.531 132.731L479.718 175.44C478.712 177.249 478.589 179.444 479.448 181.325C480.282 183.23 481.949 184.629 484.009 185.135L530.925 196.807L504.512 246.486C503.433 248.536 503.482 250.947 504.659 252.925C505.836 254.902 507.921 256.156 510.275 256.253L564.99 258.278L364.942 574.92L233.957 108.326L481.655 61.1311L481.631 61.1552Z" fill="#3C206F"/>
    <path d="M522.676 951.131L479.954 887.08C494.938 881.123 520.714 867.184 520.714 841.862C520.714 808.824 486.232 796.597 467.986 794.764H377.367V810.488H404.663V951.131H377.367V966.879H453.59V951.131H420.653V890.842H463.252L511.59 963.334C513.086 965.553 515.588 966.879 518.286 966.879H541.216V951.131H522.602H522.676ZM466.49 875.094H420.702V810.488H466.98C470.193 810.849 504.723 815.504 504.723 841.862C504.723 862.578 472.253 873.333 466.49 875.094Z" fill="#3C206F"/>
    <path d="M744.12 881.121C744.12 831.804 710.056 791.651 668.168 791.651C626.28 791.651 592.215 831.78 592.215 881.121C592.215 930.462 626.28 970.566 668.168 970.566C710.056 970.566 744.12 930.438 744.12 881.121ZM608.254 881.121C608.254 840.485 635.133 807.423 668.192 807.423C701.251 807.423 728.106 840.485 728.106 881.121C728.106 921.756 701.227 954.819 668.192 954.819C635.157 954.819 608.254 921.756 608.254 881.121Z" fill="#3C206F"/>
    <path d="M809.798 809.861V951.131H790.031V966.879H855.635V951.131H825.837V809.861H827.726L868.412 902.152L868.338 902.321L877.412 921.638L926.413 809.861H928.301V951.131H898.504V966.879H964.107V951.131H944.316V809.861H964.107V794.113H918.737L915.843 794.209L914.641 794.258V796.934L876.898 882.811L839.497 796.959V794.113H838.198H835.402H790.031V809.861H809.798Z" fill="#3C206F"/>
    <path d="M1061.98 784.891H1053.35V973.453H1070.67V944.297H1070.76V935.784H1070.67V793.38H1070.76V784.867H1061.98V784.891Z" fill="#3C206F"/>
    <path d="M1197.67 802.767V865.347H1214.99V812.051H1260.6V949.705H1217.37V966.73H1321.15V949.705H1277.92V812.051H1324.1V857.63H1341.44V795.026H1341.26V795.001H1197.72V802.767H1197.67Z" fill="#3C206F"/>
    <path d="M92.3794 127.28L0.0441667 966.729C-0.176555 968.61 0.436567 970.491 1.73637 971.914C3.01165 973.336 4.85099 974.132 6.78844 974.132L317.564 974.566C319.649 974.566 321.635 973.602 322.911 971.986C323.843 970.828 324.333 969.357 324.333 967.886C324.333 967.332 324.26 966.801 324.112 966.222L105.696 126.339C104.887 123.228 101.919 121.106 98.6331 121.347C95.3468 121.588 92.7227 124.072 92.3794 127.28ZM244.873 715.345L26.4327 850.852L101.846 165.334L244.873 715.321V715.345ZM24.3971 869.228L248.699 730.08L308.809 961.206L14.342 960.796L24.4216 869.228H24.3971Z" fill="#3C206F"/>
    <path d="M1077.45 904.176H1120.2C1120.2 904.176 1166.89 888.838 1166.94 845.598C1166.99 802.359 1122.21 789.119 1122.21 789.119H1087.43" stroke="#3C206F" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M1087.42 861.133V814.059H1120.26C1120.26 814.059 1126.93 815.626 1129.06 819.822" stroke="#3C206F" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M1110.33 867.988C1110.33 867.988 1129.95 865.167 1132.42 846.67" stroke="#3C206F" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M1098.38 891.973H1061.49" stroke="#3C206F" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M1060.98 801.52H1116.87" stroke="#3C206F" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M1081.77 904.174C1081.77 901.829 1079.84 899.929 1077.46 899.929C1075.07 899.929 1073.14 901.829 1073.14 904.174C1073.14 906.518 1075.07 908.418 1077.46 908.418C1079.84 908.418 1081.77 906.518 1081.77 904.174Z" fill="#3C206F"/>
    <path d="M1102.69 891.97C1102.69 889.626 1100.75 887.726 1098.37 887.726C1095.99 887.726 1094.05 889.626 1094.05 891.97C1094.05 894.315 1095.99 896.215 1098.37 896.215C1100.75 896.215 1102.69 894.315 1102.69 891.97Z" fill="#3C206F"/>
    <path d="M1114.63 867.986C1114.63 865.642 1112.7 863.742 1110.32 863.742C1107.93 863.742 1106 865.642 1106 867.986C1106 870.33 1107.93 872.23 1110.32 872.23C1112.7 872.23 1114.63 870.33 1114.63 867.986Z" fill="#3C206F"/>
    <path d="M1136.74 847.912C1136.74 845.568 1134.81 843.667 1132.42 843.667C1130.04 843.667 1128.11 845.568 1128.11 847.912C1128.11 850.256 1130.04 852.156 1132.42 852.156C1134.81 852.156 1136.74 850.256 1136.74 847.912Z" fill="#3C206F"/>
    <path d="M1145.37 809.638C1145.37 807.294 1143.43 805.394 1141.05 805.394C1138.67 805.394 1136.73 807.294 1136.73 809.638C1136.73 811.983 1138.67 813.883 1141.05 813.883C1143.43 813.883 1145.37 811.983 1145.37 809.638Z" fill="#3C206F"/>
    <path d="M1091.73 861.135C1091.73 858.79 1089.79 856.89 1087.41 856.89C1085.03 856.89 1083.09 858.79 1083.09 861.135C1083.09 863.479 1085.03 865.379 1087.41 865.379C1089.79 865.379 1091.73 863.479 1091.73 861.135Z" fill="#3C206F"/>
    <path d="M1133.37 820.162C1133.37 817.818 1131.44 815.917 1129.05 815.917C1126.67 815.917 1124.74 817.818 1124.74 820.162C1124.74 822.506 1126.67 824.406 1129.05 824.406C1131.44 824.406 1133.37 822.506 1133.37 820.162Z" fill="#3C206F"/>
    <path d="M1137.68 887.728C1137.68 885.384 1135.75 883.484 1133.37 883.484C1130.98 883.484 1129.05 885.384 1129.05 887.728C1129.05 890.072 1130.98 891.973 1133.37 891.973C1135.75 891.973 1137.68 890.072 1137.68 887.728Z" fill="#3C206F"/>
    <path d="M1121.19 801.521C1121.19 799.177 1119.25 797.277 1116.87 797.277C1114.49 797.277 1112.55 799.177 1112.55 801.521C1112.55 803.865 1114.49 805.766 1116.87 805.766C1119.25 805.766 1121.19 803.865 1121.19 801.521Z" fill="#3C206F"/>
    <path d="M1091.73 789.123C1091.73 786.779 1089.79 784.878 1087.41 784.878C1085.03 784.878 1083.09 786.779 1083.09 789.123C1083.09 791.467 1085.03 793.367 1087.41 793.367C1089.79 793.367 1091.73 791.467 1091.73 789.123Z" fill="#3C206F"/>
    <path d="M1132.42 887.73C1132.42 887.73 1180.54 852.015 1142.01 809.643" stroke="#3C206F" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M1062.74 788.178L1019.87 820.734" stroke="#3C206F" stroke-width="14" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1407.49 883.456C1395.55 899.217 1387.28 916.253 1384.17 931.414C1380.85 947.8 1383.96 960.304 1392.9 966.738C1401.84 973.146 1415.07 972.339 1430.14 964.42C1444.12 957.074 1458.41 944.361 1470.35 928.601C1471.69 926.856 1472.87 925.084 1474.1 923.313C1476.11 936.364 1481.6 954.39 1496.64 960.851C1514.39 968.51 1539.72 957.516 1571.87 928.184L1565.42 921.463C1536.72 947.643 1514.26 958.35 1500.5 952.437C1483.74 945.221 1482.53 915.42 1482.43 909.871C1490.54 894.97 1494.88 880.513 1494.88 868.686C1494.88 858.396 1491.66 850.112 1484.94 845.293C1476 838.885 1462.78 839.692 1447.7 847.612C1433.73 854.958 1419.46 867.67 1407.49 883.43V883.456ZM1479.35 852.717C1490.86 860.975 1486.95 891.219 1462.78 923.13C1451.64 937.823 1438.47 949.597 1425.7 956.318C1414.18 962.362 1404.3 963.456 1398.55 959.34C1394.51 956.422 1392.39 950.848 1392.39 943.397C1392.39 940.297 1392.74 936.885 1393.49 933.212C1396.3 919.353 1403.98 903.619 1415.12 888.927C1426.26 874.235 1439.43 862.46 1452.2 855.739C1463.71 849.696 1473.59 848.601 1479.35 852.717Z" fill="#3C206F"/>
    </svg>
  )
}

export default logo;