import React from 'react'

function logo1() {
  return (
    <svg width="100" height="50" viewBox="0 0 1189 738" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M552.662 0.0927718L95.0302 87.2881C93.6574 87.5435 92.4331 88.3645 91.6911 89.532C90.9305 90.6994 90.6893 92.1405 91.0418 93.4904L207.319 546.615C207.82 548.585 209.471 550.044 211.511 550.354C213.552 550.664 215.574 549.752 216.65 548.037L558.005 7.7178C558.506 6.91516 558.784 6.00315 558.784 5.05458C558.784 4.0148 558.469 3.02973 557.875 2.19061C556.725 0.548859 554.684 -0.290304 552.681 0.0927718H552.662ZM166.841 83.877L268.648 446.559L214.294 532.605L102.302 96.172L166.841 83.877ZM364.315 46.2625L348.009 82.7642C347.452 84.0229 347.434 85.4823 347.99 86.7592C348.547 88.0361 349.623 89.0394 350.94 89.5137L380.881 100.404L362.868 132.71C362.107 134.078 362.015 135.738 362.664 137.161C363.295 138.602 364.556 139.66 366.114 140.043L401.602 148.872L381.623 186.45C380.807 188.001 380.844 189.825 381.734 191.321C382.625 192.817 384.201 193.765 385.982 193.838L427.369 195.37L276.05 434.884L176.97 81.9433L364.334 46.2443L364.315 46.2625Z" fill="white"/>
    <path d="M395.363 719.459L363.048 671.009C374.382 666.504 393.879 655.96 393.879 636.806C393.879 611.815 367.797 602.566 353.995 601.18H285.449V613.074H306.096V719.459H285.449V731.371H343.105V719.459H318.192V673.855H350.414L386.978 728.69C388.11 730.368 390.002 731.371 392.043 731.371H409.388V719.459H395.308H395.363ZM352.863 661.943H318.229V613.074H353.234C355.664 613.347 381.784 616.868 381.784 636.806C381.784 652.476 357.223 660.611 352.863 661.943Z" fill="white"/>
    <path d="M562.869 666.502C562.869 629.197 537.102 598.825 505.417 598.825C473.732 598.825 447.965 629.179 447.965 666.502C447.965 703.824 473.732 734.16 505.417 734.16C537.102 734.16 562.869 703.806 562.869 666.502ZM460.097 666.502C460.097 635.764 480.429 610.755 505.436 610.755C530.442 610.755 550.755 635.764 550.755 666.502C550.755 697.239 530.424 722.248 505.436 722.248C480.447 722.248 460.097 697.239 460.097 666.502Z" fill="white"/>
    <path d="M612.546 612.599V719.459H597.594V731.371H647.217V719.459H624.678V612.599H626.107L656.882 682.41L656.827 682.538L663.691 697.15L700.755 612.599H702.184V719.459H679.644V731.371H729.268V719.459H714.297V612.599H729.268V600.687H694.949L692.76 600.76L691.851 600.797V602.822L663.301 667.781L635.011 602.84V600.687H634.028H631.913H597.594V612.599H612.546Z" fill="white"/>
    <path d="M803.303 593.716H796.773V736.348H809.87V714.293H809.945V707.854H809.87V600.137H809.945V593.697H803.303V593.716Z" fill="white"/>
    <path d="M905.938 607.232V654.57H919.034V614.256H953.539V718.379H920.834V731.258H999.341V718.379H966.636V614.256H1001.57V648.732H1014.68V601.377H1014.55V601.359H905.975V607.232H905.938Z" fill="white"/>
    <path d="M69.8776 96.283L0.0334086 731.259C-0.13355 732.682 0.330227 734.105 1.31342 735.181C2.27807 736.257 3.66938 736.859 5.13491 736.859L240.212 737.188C241.789 737.188 243.291 736.458 244.256 735.236C244.961 734.36 245.332 733.247 245.332 732.135C245.332 731.715 245.276 731.314 245.165 730.876L79.9507 95.5716C79.3385 93.2184 77.0939 91.6131 74.608 91.7955C72.1222 91.9779 70.1373 93.8569 69.8776 96.283ZM185.227 541.107L19.9942 643.608L77.0382 125.068L185.227 541.089V541.107ZM18.4545 657.508L188.121 552.253L233.589 727.082L10.8486 726.771L18.473 657.508H18.4545Z" fill="white"/>
    <path d="M815.008 683.938H847.342C847.342 683.938 882.663 672.336 882.7 639.628C882.737 606.921 848.863 596.906 848.863 596.906H822.558" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M822.547 651.383V615.775H847.387C847.387 615.775 852.432 616.961 854.046 620.135" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M839.879 656.57C839.879 656.57 854.72 654.436 856.593 640.445" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M830.838 674.711H802.938" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M802.547 606.289H844.824" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M818.272 683.946C818.272 682.173 816.81 680.735 815.007 680.735C813.204 680.735 811.742 682.173 811.742 683.946C811.742 685.719 813.204 687.156 815.007 687.156C816.81 687.156 818.272 685.719 818.272 683.946Z" fill="white"/>
    <path d="M834.096 674.711C834.096 672.938 832.635 671.501 830.831 671.501C829.028 671.501 827.566 672.938 827.566 674.711C827.566 676.484 829.028 677.922 830.831 677.922C832.635 677.922 834.096 676.484 834.096 674.711Z" fill="white"/>
    <path d="M843.135 656.567C843.135 654.794 841.674 653.356 839.87 653.356C838.067 653.356 836.605 654.794 836.605 656.567C836.605 658.34 838.067 659.777 839.87 659.777C841.674 659.777 843.135 658.34 843.135 656.567Z" fill="white"/>
    <path d="M859.854 641.379C859.854 639.606 858.392 638.169 856.589 638.169C854.786 638.169 853.324 639.606 853.324 641.379C853.324 643.152 854.786 644.59 856.589 644.59C858.392 644.59 859.854 643.152 859.854 641.379Z" fill="white"/>
    <path d="M866.377 612.43C866.377 610.657 864.916 609.22 863.113 609.22C861.309 609.22 859.848 610.657 859.848 612.43C859.848 614.203 861.309 615.641 863.113 615.641C864.916 615.641 866.377 614.203 866.377 612.43Z" fill="white"/>
    <path d="M825.803 651.391C825.803 649.618 824.342 648.18 822.538 648.18C820.735 648.18 819.273 649.618 819.273 651.391C819.273 653.164 820.735 654.602 822.538 654.602C824.342 654.602 825.803 653.164 825.803 651.391Z" fill="white"/>
    <path d="M857.303 620.395C857.303 618.622 855.842 617.184 854.038 617.184C852.235 617.184 850.773 618.622 850.773 620.395C850.773 622.168 852.235 623.605 854.038 623.605C855.842 623.605 857.303 622.168 857.303 620.395Z" fill="white"/>
    <path d="M860.565 671.5C860.565 669.727 859.103 668.29 857.3 668.29C855.497 668.29 854.035 669.727 854.035 671.5C854.035 673.274 855.497 674.711 857.3 674.711C859.103 674.711 860.565 673.274 860.565 671.5Z" fill="white"/>
    <path d="M848.092 606.289C848.092 604.516 846.631 603.079 844.827 603.079C843.024 603.079 841.562 604.516 841.562 606.289C841.562 608.063 843.024 609.5 844.827 609.5C846.631 609.5 848.092 608.063 848.092 606.289Z" fill="white"/>
    <path d="M825.803 596.914C825.803 595.141 824.342 593.704 822.538 593.704C820.735 593.704 819.273 595.141 819.273 596.914C819.273 598.688 820.735 600.125 822.538 600.125C824.342 600.125 825.803 598.688 825.803 596.914Z" fill="white"/>
    <path d="M856.586 671.5C856.586 671.5 892.983 644.484 863.839 612.433" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
    <path d="M803.872 596.198L771.445 620.824" stroke="white" stroke-width="14" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1064.66 668.268C1055.63 680.19 1049.37 693.077 1047.02 704.545C1044.51 716.939 1046.86 726.397 1053.62 731.264C1060.39 736.112 1070.39 735.501 1081.79 729.511C1092.36 723.954 1103.18 714.338 1112.21 702.417C1113.22 701.096 1114.11 699.757 1115.04 698.417C1116.56 708.289 1120.71 721.924 1132.1 726.811C1145.52 732.604 1164.68 724.289 1189 702.101L1184.12 697.018C1162.41 716.821 1145.42 724.919 1135.01 720.446C1122.33 714.988 1121.42 692.446 1121.34 688.249C1127.48 676.978 1130.76 666.042 1130.76 657.096C1130.76 649.312 1128.33 643.046 1123.25 639.401C1116.48 634.554 1106.48 635.165 1095.08 641.155C1084.51 646.711 1073.71 656.327 1064.66 668.249V668.268ZM1119.01 645.017C1127.72 651.263 1124.76 674.14 1106.48 698.279C1098.05 709.392 1088.09 718.299 1078.43 723.383C1069.72 727.954 1062.25 728.782 1057.89 725.668C1054.84 723.461 1053.24 719.245 1053.24 713.609C1053.24 711.264 1053.5 708.683 1054.07 705.904C1056.19 695.422 1062.01 683.52 1070.43 672.406C1078.86 661.293 1088.82 652.386 1098.48 647.303C1107.19 642.731 1114.66 641.904 1119.01 645.017Z" fill="white"/>
    </svg>
  )
}

export default logo1