import React from 'react'
import { useSelector ,useDispatch} from 'react-redux'
import {  useState,useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import {setIstoken} from "../../slices/profileSlice"
import { Navigate ,Outlet} from 'react-router-dom'



const ProtectedRouteAdmin = () => {

  const dispatch = useDispatch()
  const {user,istoken} = useSelector((state)=>state.profile)
  const {profile} = useSelector((state)=>state.profileData)

    useEffect(() =>{
      if (user && user.token) {
        const decodedToken = jwtDecode(user.token);
        // console.log("decoded token",decodedToken)
        const currentTime = Date.now() / 1000;
        // console.log("current time",currentTime)
        // console.log(currentTime < decodedToken.exp)
        dispatch(setIstoken(currentTime < decodedToken.exp));
        // console.log(istoken)
      } else {
        dispatch(setIstoken(false));
      }
    },[user])
 



    if (!user) {
      return <Navigate to="/login" />;
    }

    if (user.accountType !== "Admin") {
        return <Navigate to="/" />;
      }

  if (!istoken) {
    return <Navigate to="/login" />;
  }

  if (!profile.firstname || !profile.lastname ) {
    return <Navigate to="/profileupdate" />;
  }

  return <Outlet />;
};

export default ProtectedRouteAdmin;
