import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux'
import  { Toaster } from 'react-hot-toast';

import persistedReducer from './reducer/index.js'
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
export const store = configureStore({
  reducer: persistedReducer,
})
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
      <BrowserRouter>
        <App className="bg-[#F5F7FA]" />
        <Toaster />

      </BrowserRouter>
      </PersistGate >
    </Provider>

  
);


reportWebVitals();
