import React from 'react'
import toast from 'react-hot-toast';
import "../../pages/signin.css"
import { useSelector } from 'react-redux'
import { apiConnector } from '../../service/apiconnector'
import { useState, useRef, useEffect } from 'react';
import img19 from '../../assets/img19.png';
import img25 from "../../assets/img25.png"
import { useParams, Link } from 'react-router-dom';
import animationData from '../../assets/lotties/course.lottie';
import '@dotlottie/player-component';
import { Button, buttonVariants } from "../../component/ui/button"
import { useNavigate } from 'react-router-dom'

function SelfAssessment({ forloading, popup }) {
  const { token } = useSelector((state) => state.auth);
  const [submissionData, setsubmissionData] = useState();
  const [question, setQuestion] = useState([]);
  const [userselftotal, setUserSelftotal] = useState()
  let { AssignmentId } = useParams();
  const [asessmentData, setAssessmentData] = useState();
  const { user } = useSelector((state) => state.profile)
  const { assignmentData } = useSelector((state) => state.profile)
  const [userfinalResponses, setUserFinalResponses] = useState([]);
  const [selfState, setselfState] = useState(false)
  const [usermarkGot, setUsermarkGot] = useState()
  const [marksGot1, setmarksGot1] = useState()
  const navigate = useNavigate();




  // fetching the task data 


  useEffect(() => {

    const fetchdata = async () => {
      try {
        const Result = await apiConnector('GET', process.env.REACT_APP_BASE_URL + `/api/get_course/${user._id}/${assignmentData._id}`, null, {
          Authorization: `Bearer ${token}`,
        });

        if (Result.data.success) {
          setAssessmentData(Result.data.course);
        }


      } catch (error) {
        console.log(error);
      }
    }

    fetchdata();
  }, []);



  useEffect(() => {
    console.log(asessmentData)
  }, [asessmentData])

  useEffect(() => {
    if (asessmentData && asessmentData?.userQuestions[0]?.response.length > 0) {
      const markGotSum = asessmentData?.userQuestions[0]?.response.reduce((sum, response) => {
        return sum + response.responses.reduce((responseSum, ques) => {
          return responseSum + ques.markGot;
        }, 0);
      }, 0);
      setUsermarkGot(markGotSum)
      // console.log(markGotSum)

    }
  }, [asessmentData]);







  useEffect(() => {
    let totalMarks;
    if (question && question[0]?.userQues && question[0]?.userQues?.length > 0) {
      totalMarks = question[0]?.userQues.reduce((accumulator, currentValue) => {
        currentValue.questions.forEach((question) => {
          accumulator += question.mark;
        });
        return accumulator;
      }, 0);
      setUserSelftotal(totalMarks)
    }

  }, [question])



  // fetching the questions data 


  useEffect(() => {
    const fetchdata = async () => {
      try {
        const allQues = await apiConnector('GET', `${process.env.REACT_APP_BASE_URL}/api/v2/question_data/${assignmentData.assignmentType}`, null, {
          Authorization: `Bearer ${token}`,
        });
        setQuestion(allQues.data.allques);

      } catch (error) {
        console.log(error);
      }
    };

    fetchdata();
  }, [assignmentData]);







  // fetching the course
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const response = await apiConnector('GET', process.env.REACT_APP_BASE_URL + `/api/get_submission/${user._id}/${AssignmentId}`, null, {
          Authorization: `Bearer ${token}`,
        });

        setsubmissionData(response.data.submission)
      } catch (error) {
        console.log(error);
      }
    }

    fetchdata();
  }, []);





  useEffect(() => {
    let totalMarks;
    if (userfinalResponses && userfinalResponses.length > 0) {
      totalMarks = userfinalResponses.reduce((accumulator, currentValue) => {
        currentValue.responses.forEach((question) => {
          accumulator += question.markGot;
        });
        return accumulator;
      }, 0);
      // console.log(totalMarks)
      setmarksGot1(totalMarks)
    }
  }, [userfinalResponses]);







  const selfassessmenthandler = async (event) => {
    event.preventDefault();

    try {

      forloading(true)
      const result = await apiConnector('POST', process.env.REACT_APP_BASE_URL + `/api/selfsubmit/${user._id}/${AssignmentId}`, {
        assignmentId: assignmentData._id,
        assignmentTopic: assignmentData.assignmentTopic,
        assignmentType: assignmentData.assignmentType,
        selftotal: userselftotal,
        marksGot: marksGot1,
        userfinalResponses
      }, {
        Authorization: `Bearer ${token}`,
      });

      console.log(result)

      if (!result.data.success) {
        toast.error("failed to submit")
        forloading(false)
        throw new Error(result.data.message);
      }

      // console.log(result);

      setUserFinalResponses([])

      toast.success('Self Assessment Submitted Successfully');

      popup({
        marksGot: marksGot1,
        selfsubmitted: true,
        selftotal: userselftotal
      })



      const Result = await apiConnector('GET', process.env.REACT_APP_BASE_URL + `/api/get_course/${user._id}/${assignmentData._id}`, null, {
        Authorization: `Bearer ${token}`,
      });

      console.log(Result)
      if (Result.data.success) {
        setAssessmentData(Result.data.course);
      }



      forloading(false)


    } catch (error) {
      forloading(false)
      toast.error(error.response?.data?.message || 'Failed to submit');

    }
  };












  useEffect(() => {
    if (Array.isArray(question) && question.length > 0) {
      const userresponses = question[0]?.userQues?.map((ques) => ({
        headingType: ques.headingType,
        responses: ques.questions.map((questions) => ({
          question: questions.ques,
          answer: null,
          markGot: 0,
          actualMark: questions.mark,
        })),
      }));
      setUserFinalResponses(userresponses);
    }
  }, [question]);









  return (
    <div>

      <div className=' mt-10 mb-2 ml-2 flex items-center gap-2 text-xl'>
        <h1 className='text-xl font-bold'>Self Assessment</h1>
        <div >
          <img src={img19} alt="" className='w-8  ' />

        </div>
      </div>

      <div>



        
        {(!asessmentData || !asessmentData?.userQuestions[0]?.submitted || !asessmentData?.userQuestions[0]?.response.length > 0) && question &&

          question[0]?.userQues?.map((peerQues, index) => (
            <div key={index}>
              <div className='font-semibold font-roboto underline'>{peerQues.headingType}</div>

              {peerQues.questions.map((question, idx) => (
                <div key={idx} className='text-richlue-900 bg-richblue-10 m-2 p-2 rounded-md flex justify-between items-center shadow-lg'>
                  <div className='flex flex-col max-w-[70%] min-w-[70%]'>
                    <div className='text-richblue-300 font-semibold'>
                      <p>{idx + 1}. {question.ques}</p>
                    </div>

                    {
                      question.quesType === "Response" && (
                        <div>
                          <div>
                            <p className='font-roboto text-sm font-semibold'>Response :</p>
                            <textarea
                              className='number-input border-b-[1px] border-0 min-w-[60%]'
                              rows="2"
                              required='required'
                              onChange={(e) => {
                                const value = e.target.value;

                                setUserFinalResponses((prevResponses) => {
                                  const updatedResponses = [...prevResponses];

                                  updatedResponses[index].responses[idx].answer = value;

                                  return updatedResponses;
                                });
                              }}
                              value={userfinalResponses[index]?.responses[idx]?.answer || null}
                            />
                          </div>
                        </div>
                      )}
                  </div>



                  {question.quesType !== "Response" && (
                    <div className='flex flex-col gap-2 sm:flex-row '>
                      <div className='flex'>
                        <div className='border-b-[1px]'>
                          <input
                            type="number"
                            min="0"
                            required='required'
                            max={question.mark}
                            className="border-0 w-6 h-6 p-0 number-input focus:outline-0 bg-none"
                            onWheel={(e) => e.currentTarget.blur()}
                            onChange={(e) => {
                              const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), userfinalResponses[index].responses[idx]?.actualMark || 0);

                              setUserFinalResponses((prevResponses) => {
                                const updatedResponses = [...prevResponses];

                                updatedResponses[index].responses[idx].markGot = value;

                                return updatedResponses;
                              });
                            }}
                            value={userfinalResponses[index]?.responses[idx]?.markGot || 0}
                          />
                        </div>
                        <p>/ </p>
                        <div>
                          <p>{question.mark}</p>
                        </div>
                      </div>

                      <div className='bg-richblue-600 w-6 h-6 rounded-full text-richblue-10 flex justify-center items-center '>
                        <p>{userfinalResponses[index]?.responses[idx]?.markGot || 0}</p>
                      </div>
                    </div>
                  )}


                </div>
              ))}
            </div>
          ))
        }



        {(!asessmentData || !asessmentData?.userQuestions[0]?.submitted || !asessmentData?.userQuestions[0]?.response.length > 0) && <div className='flex m-6 my-20 justify-center items-center'>
          <Button variant='btn' onClick={selfassessmenthandler}>
          Next
          </Button>
       
        </div>

        }


      </div>





      {(asessmentData && asessmentData?.userQuestions[0]?.submitted && asessmentData?.userQuestions[0]?.response.length > 0) &&
        <div className="flex text-richblue-900 justify-center items-center bg-primary text-richblue-10 gap-4 shadow-md w-[150px] rounded-md">
          <div className='flex flex-col justify-center items-center'>

            <img src={img25} alt="" className="w-8" />
            <p className="text-md font-semibold">Score</p>
          </div>
          <div>
            <span className=" font-semibold ">{usermarkGot}</span><span className="font-semibold text-sm opacity-80">/ {userselftotal}</span>
          </div>
        </div>

      }



      {(asessmentData && asessmentData?.userQuestions[0]?.submitted && asessmentData?.userQuestions[0]?.response.length > 0) &&

        <div className=' mt-10 mb-2 ml-2 flex items-center gap-2 '>
          <h1 className='text-lg font-bold'>Self Assessment Response</h1>
        </div>


      }



      {(asessmentData && asessmentData?.userQuestions[0]?.submitted && asessmentData?.userQuestions[0]?.response.length > 0) &&
        asessmentData?.userQuestions[0]?.response?.map((response, index) => {
          return (
            <div key={index}>
              {response.responses.length > 0 && <div className='font-semibold font-roboto underline'>{response.headingType}</div>}

              {response.responses.map((ques, idx) => {
                return (
                  <div key={idx} className='text-richlue-900 bg-richblue-10 m-2 p-2 rounded-md flex justify-between items-center shadow-xl cursor-pointer'>
                    <div className='flex flex-col max-w-[70%] min-w-[70%]' >
                      <div className='text-richblue-300 font-semibold min-w-[70%]'>
                        <p>
                          {idx + 1}. {ques.question}
                        </p>
                      </div>
                      <div>
                        {ques.answer !== null && (
                          <div className='min-w-[60%] text-sm font-roboto'>
                            <p className='font-roboto text-sm font-semibold '>Response :</p>

                            {ques.answer}
                          </div>
                        )}
                      </div>
                    </div>
                    {ques.answer === null && <div className='bg-richblue-600  w-8 h-8 rounded-full text-richblue-10 flex justify-center items-center '>
                      <p>{ques.markGot}/{ques.actualMark}</p>
                    </div>

                    }


                  </div>
                );
              })}
            </div>
          );
        })
        
        
        }





      {(asessmentData && asessmentData?.userQuestions[0]?.submitted && asessmentData?.userQuestions[0]?.response.length > 0) &&
        <div className='flex flex-col gap-2 justify-center text-3xl text-gray-800 items-center my-20 font-bold '>
             <dotlottie-player
                            autoplay
                            playMode="normal"
                            src={animationData}
            background="transparent"
            speed="0.5"
            style={{ width: '100px', height: '100px' }}
          />
          <p>Thanks for completing the self-assessment !</p>

          <Button onClick={()=>{
            navigate(`/students/new_submission/${AssignmentId}/${assignmentData._id}/peer`)
          }}>
            Proceed to Peer Assessment
          </Button>
        </div>

      }



    </div>
  )
}

export default SelfAssessment



































